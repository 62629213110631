<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div>
            <b>Forgot Password</b>
            <div>Please enter your email and you will get an email with a link to reset your password</div>
          </div>

          <!-- form -->
          <validation-observer
            ref="forgotForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-forgot-form mt-2"
              @submit.prevent="forgot"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-link
                :to="{ name: 'auth-login'}"
              >
                Back to login page
              </b-link>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-1"
                block
                :disabled="invalid"
              >
                Forgot Password
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: 'name@company.com',
      sideImg: require('@/assets/images/logo/logo-idleaf.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-idleaf.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    forgot() {
      this.$refs.forgotForm.validate().then(success => {
        if (success) {
          this.$http.post('/api/v1/users/reset_password/', { email: this.userEmail })
            .then(() => this.$toast({
              component: ToastificationContent,
              props: {
                title: `Instructions sent to ${this.userEmail}`,
                icon: 'SentIcon',
                variant: 'success',
              },
            }))
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Forgot Password error',
                  text: error.response.data.detail,
                  icon: 'CoffeIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
